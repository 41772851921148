import React, { useState } from "react"
import landingGridStyles from "./landingGrid.module.scss"

import { graphql, useStaticQuery, Link, navigate } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { useSpring, animated } from "react-spring"
import Newsletter from "../newsletter/newsletter"
import ContainerLoader from "../containerLoader/containerLoader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons"

function LandingGrid() {
  const landingGridData = useStaticQuery(graphql`
    query {
      contentfulLandingGrid {
        title
        verticalImageAlt
        verticalPhoto {
          fluid(maxHeight: 600, quality: 100) {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
        verticalText
        verticalTextColor
        verticalPhotoInternalSlug
        verticalPhotoExternalLink
        verticalButton
        verticalButtonText
        verticalButtonInternalSlug
        verticalButtonExternalLink
        verticalTextButtonPlacement
        horizontalImageAlt
        horizontalPhoto {
          fluid(maxHeight: 350, quality: 100) {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
        horizontalText
        horizontalTextColor
        horizontalPhotoInternalSlug
        horizontalPhotoExternalLink
        horizontalButton
        horizontalButtonText
        horizontalButtonInternalSlug
        horizontalButtonExternalLink
        horizontalTextButtonPlacement
      }
    }
  `)

  const gridData = landingGridData.contentfulLandingGrid

  const vContent = () => {
    if (gridData.verticalTextButtonPlacement === "Top Left") {
      return { alignItems: "flex-start", justifyContent: "flex-start" }
    } else if (gridData.verticalTextButtonPlacement === "Top Center") {
      return { alignItems: "flex-start", justifyContent: "center" }
    } else if (gridData.verticalTextButtonPlacement === "Top Right") {
      return { alignItems: "flex-start", justifyContent: "flex-end" }
    } else if (gridData.verticalTextButtonPlacement === "Middle Left") {
      return { alignItems: "center", justifyContent: "flex-start" }
    } else if (gridData.verticalTextButtonPlacement === "Middle Center") {
      return { alignItems: "center", justifyContent: "center" }
    } else if (gridData.verticalTextButtonPlacement === "Middle Right") {
      return { alignItems: "center", justifyContent: "flex-end" }
    } else if (gridData.verticalTextButtonPlacement === "Bottom Left") {
      return { alignItems: "flex-end", justifyContent: "flex-start" }
    } else if (gridData.verticalTextButtonPlacement === "Bottom Center") {
      return { alignItems: "flex-end", justifyContent: "center" }
    } else if (gridData.verticalTextButtonPlacement === "Bottom Right") {
      return { alignItems: "flex-end", justifyContent: "flex-end" }
    } else {
      return
    }
  }

  const vItem = () => {
    if (gridData.verticalTextButtonPlacement === "Top Left") {
      return { textAlign: "left" }
    } else if (gridData.verticalTextButtonPlacement === "Top Center") {
      return { textAlign: "center" }
    } else if (gridData.verticalTextButtonPlacement === "Top Right") {
      return { textAlign: "right" }
    } else if (gridData.verticalTextButtonPlacement === "Middle Left") {
      return { textAlign: "left" }
    } else if (gridData.verticalTextButtonPlacement === "Middle Center") {
      return { textAlign: "center" }
    } else if (gridData.verticalTextButtonPlacement === "Middle Right") {
      return { textAlign: "right" }
    } else if (gridData.verticalTextButtonPlacement === "Bottom Left") {
      return { textAlign: "left" }
    } else if (gridData.verticalTextButtonPlacement === "Bottom Center") {
      return { textAlign: "center" }
    } else if (gridData.verticalTextButtonPlacement === "Bottom Right") {
      return { textAlign: "right" }
    } else {
      return
    }
  }

  const hContent = () => {
    if (gridData.horizontalTextButtonPlacement === "Top Left") {
      return { alignItems: "flex-start", justifyContent: "flex-start" }
    } else if (gridData.horizontalTextButtonPlacement === "Top Center") {
      return { alignItems: "flex-start", justifyContent: "center" }
    } else if (gridData.horizontalTextButtonPlacement === "Top Right") {
      return { alignItems: "flex-start", justifyContent: "flex-end" }
    } else if (gridData.horizontalTextButtonPlacement === "Middle Left") {
      return { alignItems: "center", justifyContent: "flex-start" }
    } else if (gridData.horizontalTextButtonPlacement === "Middle Center") {
      return { alignItems: "center", justifyContent: "center" }
    } else if (gridData.horizontalTextButtonPlacement === "Middle Right") {
      return { alignItems: "center", justifyContent: "flex-end" }
    } else if (gridData.horizontalTextButtonPlacement === "Bottom Left") {
      return { alignItems: "flex-end", justifyContent: "flex-start" }
    } else if (gridData.horizontalTextButtonPlacement === "Bottom Center") {
      return { alignItems: "flex-end", justifyContent: "center" }
    } else if (gridData.horizontalTextButtonPlacement === "Bottom Right") {
      return { alignItems: "flex-end", justifyContent: "flex-end" }
    } else {
      return
    }
  }

  const hItem = () => {
    if (gridData.horizontalTextButtonPlacement === "Top Left") {
      return { textAlign: "left" }
    } else if (gridData.horizontalTextButtonPlacement === "Top Center") {
      return { textAlign: "center" }
    } else if (gridData.horizontalTextButtonPlacement === "Top Right") {
      return { textAlign: "right" }
    } else if (gridData.horizontalTextButtonPlacement === "Middle Left") {
      return { textAlign: "left" }
    } else if (gridData.horizontalTextButtonPlacement === "Middle Center") {
      return { textAlign: "center" }
    } else if (gridData.horizontalTextButtonPlacement === "Middle Right") {
      return { textAlign: "right" }
    } else if (gridData.horizontalTextButtonPlacement === "Bottom Left") {
      return { textAlign: "left" }
    } else if (gridData.horizontalTextButtonPlacement === "Bottom Center") {
      return { textAlign: "center" }
    } else if (gridData.horizontalTextButtonPlacement === "Bottom Right") {
      return { textAlign: "right" }
    } else {
      return
    }
  }

  const vButton = () => {
    if (gridData.verticalButton) {
      if (gridData.verticalButtonInternalSlug !== "#") {
        return (
          <Link
            to={`${gridData.verticalButtonInternalSlug}`}
            className={landingGridStyles.vLink}
          >
            <button className={landingGridStyles.verticalButton}>
              {gridData.verticalButtonText}
            </button>
          </Link>
        )
      } else if (gridData.verticalButtonExternalLink !== "#") {
        return (
          <a
            href={`${gridData.verticalButtonExternalLink}`}
            target="_blank"
            rel="noreferrer"
          >
            <button className={landingGridStyles.verticalButton}>
              {gridData.verticalButtonText}
            </button>
          </a>
        )
      } else {
        return (
          <button className={landingGridStyles.verticalButton}>
            {gridData.verticalButtonText}
          </button>
        )
      }
    } else {
      return
    }
  }

  const hButton = () => {
    if (gridData.horizontalButton) {
      if (gridData.horizontalButtonInternalSlug !== "#") {
        return (
          <Link
            to={`${gridData.horizontalButtonInternalSlug}`}
            className={landingGridStyles.hLink}
          >
            <button className={landingGridStyles.horizontalButton}>
              {gridData.horizontalButtonText}
            </button>
          </Link>
        )
      } else if (gridData.horizontalButtonExternalLink !== "#") {
        return (
          <a
            href={`${gridData.horizontalButtonExternalLink}`}
            target="_blank"
            rel="noreferrer"
          >
            <button className={landingGridStyles.horizontalButton}>
              {gridData.horizontalButtonText}
            </button>
          </a>
        )
      } else {
        return (
          <button className={landingGridStyles.horizontalButton}>
            {gridData.horizontalButtonText}
          </button>
        )
      }
    } else {
      return
    }
  }

  const handleVerticalClick = () => {
    if (
      gridData.verticalPhotoInternalSlug === "#" &&
      gridData.verticalPhotoExternalLink === "#"
    ) {
      return
    } else if (gridData.verticalPhotoInternalSlug !== "#") {
      navigate(`${gridData.verticalPhotoInternalSlug}`)
    } else if (gridData.verticalPhotoExternalLink !== "#") {
      window.open(gridData.verticalPhotoExternalLink, "_blank")
    } else if (
      gridData.verticalPhotoInternalSlugg === "#" &&
      gridData.verticalPhotoExternalLink === "#"
    ) {
      return
    } else {
      console.log("Error in handling background click vertical grid click")
    }
  }

  const handleHorizontalClick = () => {
    if (
      gridData.horizontalPhotoInternalSlug === "#" &&
      gridData.horizontalPhotoExternalLink === "#"
    ) {
      return
    } else if (gridData.horizontalPhotoInternalSlug !== "#") {
      navigate(`${gridData.horizontalPhotoInternalSlug}`)
    } else if (gridData.horizontalPhotoExternalLink !== "#") {
      window.open(gridData.horizontalPhotoExternalLink, "_blank")
    } else if (
      gridData.horizontalPhotoInternalSlug === "#" &&
      gridData.horizontalPhotoExternalLink === "#"
    ) {
      return
    } else {
      console.log("Error in handling background click horizontal grid click")
    }
  }

  const isVClick =
    gridData.horizontalPhotoInternalSlug !== "#" ||
    gridData.horizontalPhotoExternalLink !== "#"

  const isHClick =
    gridData.horizontalPhotoInternalSlug !== "#" ||
    gridData.horizontalPhotoExternalLink !== "#"

  //3D card animation
  const calc = (x, y) => [
    -(y - window.innerHeight / 2) / 250,
    (x - window.innerWidth / 2) / 250,
    1.001,
  ]

  const transVCard = (x, y, s) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

  const [animateVCard, setAnimateVCard] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }))

  const [vShadow, setVshadow] = useState(false)

  const shadowVCard = useSpring({
    boxShadow: vShadow
      ? "0px 10px 30px -5px rgba(0, 0, 0, 0.3)"
      : "0px 0px 0px 0px rgba(0, 0, 0, 0.0)",
  })

  const transHCard = (x, y, s) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

  const [animateHCard, setAnimateHCard] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }))

  const [hShadow, setHshadow] = useState(false)

  const shadowHCard = useSpring({
    boxShadow: hShadow
      ? "0px 10px 30px -5px rgba(0, 0, 0, 0.3)"
      : "0px 0px 0px 0px rgba(0, 0, 0, 0.0)",
  })

  const transNewsCard = (x, y, s) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

  const [animateNewsCard, setAnimateNewsCard] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }))

  const [newsShadow, setNewsShadow] = useState(false)

  const shadowNewsCard = useSpring({
    boxShadow: newsShadow
      ? "0px 10px 30px -5px rgba(0, 0, 0, 0.3)"
      : "0px 0px 0px 0px rgba(0, 0, 0, 0.0)",
  })

  const [isLoading, setIsLoading] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(null)
  const [isError, setIsError] = useState(false)
  const [message, setMessage] = useState("")

  // useEffect(() => {}, [isLoading, isSuccessful, isError, message])

  const { transform, opacity } = useSpring({
    opacity: isSuccessful || isError ? 1 : 0,
    transform: `perspective(600px) rotateX(${
      isSuccessful || isError ? 180 : 0
    }deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  })

  return (
    <div className={landingGridStyles.container}>
      <animated.div
        onKeyDown={isVClick ? handleVerticalClick : null}
        className={landingGridStyles.verticalGridItem}
        onClick={isVClick ? handleVerticalClick : null}
        role="button"
        tabIndex={isVClick ? "0" : null}
        onMouseEnter={() => setVshadow(true)}
        onMouseMove={({ clientX: x, clientY: y }) =>
          setAnimateVCard({ xys: calc(x, y) })
        }
        onMouseLeave={() => {
          setAnimateVCard({ xys: [0, 0, 1] })
          setVshadow(false)
        }}
        style={{ ...shadowVCard, transform: animateVCard.xys.to(transVCard) }}
      >
        <BackgroundImage
          className={landingGridStyles.verticalPhoto}
          fluid={gridData.verticalPhoto.fluid}
          alt={gridData.verticalImageAlt}
          style={vContent()}
        >
          <div
            className={landingGridStyles.verticalTextButtonContainer}
            style={vItem()}
          >
            <h3
              className={landingGridStyles.verticalText}
              style={{ color: `${gridData.verticalTextColor}` }}
            >
              {gridData.verticalText}
            </h3>
            {vButton()}
          </div>
        </BackgroundImage>
      </animated.div>

      <animated.div
        id="landingNewsletter"
        className={landingGridStyles.newsletter}
        onMouseEnter={() => setNewsShadow(true)}
        onMouseMove={({ clientX: x, clientY: y }) =>
          setAnimateNewsCard({ xys: calc(x, y) })
        }
        onMouseLeave={() => {
          setAnimateNewsCard({ xys: [0, 0, 1] })
          setNewsShadow(false)
        }}
        style={{
          ...shadowNewsCard,
          transform: animateNewsCard.xys.to(transNewsCard),
        }}
      >
        {isLoading ? <ContainerLoader /> : null}

        <animated.div
          className={landingGridStyles.newsletterFront}
          style={{ opacity: opacity.to(o => 1 - o), transform }}
        >
          <h3 className={landingGridStyles.newsletterHeading}>
            Stay updated with Lilly Feather
          </h3>
          <p className={landingGridStyles.newsletterText}>
            We'll hit you up with exclusive offers, new releases and more!
          </p>
          <Newsletter
            setIsLoading={setIsLoading}
            setIsSuccessful={setIsSuccessful}
            setIsError={setIsError}
            setMessage={setMessage}
          />
        </animated.div>

        {isSuccessful || isError ? (
          <animated.div
            className={landingGridStyles.newsletterBack}
            style={{
              opacity,
              transform: transform.to(t => `${t} rotateX(180deg)`),
            }}
          >
            {isError ? (
              <h2 className={landingGridStyles.flipErrorMessage}>{message}</h2>
            ) : (
              <h2 className={landingGridStyles.flipMessage}>{message}</h2>
            )}
            <div className={landingGridStyles.plane}>
              <FontAwesomeIcon icon={faPaperPlane} />
            </div>
          </animated.div>
        ) : null}
      </animated.div>

      <animated.div
        onKeyDown={isHClick ? handleHorizontalClick : null}
        className={landingGridStyles.horizontalGridItem}
        onClick={isHClick ? handleHorizontalClick : null}
        role="button"
        tabIndex={isHClick ? "0" : null}
        onMouseEnter={() => setHshadow(true)}
        onMouseMove={({ clientX: x, clientY: y }) =>
          setAnimateHCard({ xys: calc(x, y) })
        }
        onMouseLeave={() => {
          setAnimateHCard({ xys: [0, 0, 1] })
          setHshadow(false)
        }}
        style={{ ...shadowHCard, transform: animateHCard.xys.to(transHCard) }}
      >
        <BackgroundImage
          className={landingGridStyles.horizontalPhoto}
          fluid={gridData.horizontalPhoto.fluid}
          alt={gridData.horizontalImageAlt}
          style={hContent()}
        >
          <div
            className={landingGridStyles.horizontalTextButtonContainer}
            style={hItem()}
          >
            <h3
              className={landingGridStyles.horizontalText}
              style={{ color: `${gridData.horizontalTextColor}` }}
            >
              {gridData.horizontalText}
            </h3>
            {hButton()}
          </div>
        </BackgroundImage>
      </animated.div>
    </div>
  )
}

export default LandingGrid
