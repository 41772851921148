import React from "react"
import { graphql, useStaticQuery, navigate, Link } from "gatsby"

import heroCarouselStyles from "./heroCarousel.module.scss"
// import { useSpring, animated, config } from "react-spring"
// import Img from "gatsby-image"

import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"
import "swiper/components/pagination/pagination.scss"
import "../../utilities/swiperStyles/swiperColor.scss"

function HeroCarousel() {
  // const [hoverButton, setHoverButton] = useState("hoverFalse")

  // const handleButtonEnter = () => {
  //   setHoverButton("hoverTrue")
  // }

  // const handleButtonLeave = () => {
  //   setHoverButton("hoverFalse")
  // }

  // const buttonAnimation = useSpring({
  //   background:
  //     hoverButton === "hoverTrue"
  //       ? "linear-gradient(90deg, rgba(220,74,117,1) 0%, rgba(112,17,107,1) 100%)"
  //       : "linear-gradient(0deg, rgba(220,74,117,1) 0%, rgba(112,17,107,1) 0%)",
  //   config: config.wobble,
  // })

  // const Button = animated.button

  const carouselData = useStaticQuery(graphql`
    query {
      allContentfulHeroCarousel(sort: { fields: rankingOrder, order: ASC }) {
        edges {
          node {
            title
            rankingOrder
            mainText
            mainTextColor
            supportText
            supportTextColor
            textButtonAlignment
            button
            buttonText
            buttonInternalSlug
            buttonExternalLink
            background {
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
              file {
                url
                contentType
              }
            }
            overlayOpacity
            mediaAlt
            backgroundInternalSlug
            backgroundExternalLink
          }
        }
      }
    }
  `)

  const backgroundMedia = node => {
    let fileType = node.background.file.contentType

    if (fileType === "video/mp4") {
      return (
        <video
          key={`${node.title} video`}
          className={heroCarouselStyles.media}
          alt={node.mediaAlt}
          preload="auto"
          loop
          autoPlay
          muted
          playsInline
        >
          <source src={node.background.file.url} type="video/mp4" />
        </video>
      )
    } else if (
      fileType === "image/png" ||
      fileType === "image/jpeg" ||
      fileType === "gif"
    ) {
      return (
        //   <Img
        //   className={heroCarouselStyles.media}
        //   fluid={node.background.fluid}
        //   key={`${node.title} image`}
        //   alt={node.mediaAlt}
        // />
        <img
          src={node.background.file.url}
          className={heroCarouselStyles.media}
          key={`${node.title} image`}
          alt={node.mediaAlt}
        />
      )
    } else {
      return console.log("invalid hero carousel image")
    }
  }

  const overlay = node => {
    const handleBackgroundClick = () => {
      if (
        node.backgroundInternalSlug === "#" &&
        node.backgroundExternalLink === "#"
      ) {
        return
      } else if (node.backgroundInternalSlug !== "#") {
        navigate(`${node.backgroundInternalSlug}`)
      } else if (node.backgroundExternalLink !== "#") {
        window.open(node.backgroundExternalLink, "_blank")
      } else if (
        node.backgroundInternalSlug === "#" &&
        node.backgroundExternalLink === "#"
      ) {
        return
      } else {
        console.log("Error in handling background click hero carousel")
      }
    }

    const containerAlignmentStyle = () => {
      if (node.textButtonAlignment === "Left") {
        return "flex-start"
      } else if (node.textButtonAlignment === "Center") {
        return "center"
      } else if (node.textButtonAlignment === "Right") {
        return "flex-end"
      } else {
        return
      }
    }

    const cursorStyle = () => {
      if (node.backgroundExternalLink !== "#") {
        return "pointer"
      } else {
        return "default"
      }
    }

    return (
      <div
        role="button"
        tabIndex="0"
        onClick={handleBackgroundClick}
        onKeyDown={handleBackgroundClick}
        key={`${node.title} overlay`}
        className={heroCarouselStyles.overlay}
        style={{
          backgroundColor: `rgba(0, 0, 0, ${node.overlayOpacity})`,
          justifyContent: `${containerAlignmentStyle()}`,
          cursor: `${cursorStyle()}`,
        }}
      >
        {textButtonContainer(node)}
      </div>
    )
  }

  const textButtonContainer = node => {
    // const handleButtonClick = () => {
    //   if (node.buttonInternalSlug === "#" && node.buttonExternalLink === "#") {
    //     return
    //   } else if (node.buttonExternalLink === "#") {
    //     navigate(`${node.buttonInternalSlug}`)
    //   } else if (node.buttonInternalSlug === "#") {
    //     window.open(node.buttonExternalLink, "_blank")
    //   } else {
    //     console.log("Error in handling button click hero carousel")
    //   }
    // }

    const textAlign = () => {
      if (node.textButtonAlignment === "Left") {
        return "left"
      } else if (node.textButtonAlignment === "Center") {
        return "center"
      } else if (node.textButtonAlignment === "Right") {
        return "right"
      } else {
        return
      }
    }

    return (
      <div
        key={`${node.title} container`}
        className={heroCarouselStyles.container}
      >
        <div
          key={`${node.title} contentContainer`}
          className={heroCarouselStyles.contentContainer}
        >
          <div
            key={`${node.title} textButtonContainer`}
            className={heroCarouselStyles.textButtonContainer}
            style={{ textAlign: `${textAlign()}` }}
          >
            {node.mainText === "#" ? null : (
              <h2
                key={`${node.title} mainText`}
                className={heroCarouselStyles.mainText}
                style={{ color: `${node.mainTextColor}` }}
              >
                {node.mainText}
              </h2>
            )}
            {node.supportText === "#" ? null : (
              <p
                key={`${node.title} supportText`}
                className={heroCarouselStyles.supportText}
                style={{ color: `${node.supportTextColor}` }}
              >
                {node.supportText}
              </p>
            )}
            {node.button ? (
              node.buttonInternalSlug === "#" &&
              node.buttonExternalLink ===
                "#" ? null : node.buttonExternalLink === "#" ? (
                <Link
                  className={heroCarouselStyles.buttonLink}
                  to={node.buttonInternalSlug}
                >
                  <span className={heroCarouselStyles.centerer}></span>
                  <span className={heroCarouselStyles.centered}>
                    {node.buttonText}
                  </span>
                </Link>
              ) : node.buttonInternalSlug === "#" ? (
                <a
                  href={node.buttonExternalLink}
                  className={heroCarouselStyles.buttonLink}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <span className={heroCarouselStyles.centerer}></span>
                  <span className={heroCarouselStyles.centered}>
                    {node.buttonText}
                  </span>
                </a>
              ) : null
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  //   const sliderSettings = {
  //     dots: true,
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 1,
  //     slidesToScroll: 1,
  //     arrows: true,
  //     lazyLoad: "progressive",
  //   }

  const renderSlides = () => {
    return carouselData.allContentfulHeroCarousel.edges.map(({ node }) => {
      return (
        <SwiperSlide
          key={`${node.title} slide`}
          className={heroCarouselStyles.slide}
        >
          {backgroundMedia(node)}
          {overlay(node)}
        </SwiperSlide>
      )
    })
  }

  SwiperCore.use([Navigation, Pagination, Autoplay])

  return (
    <>
      <Swiper
        id="swiper-color"
        autoplay={{ delay: 15000 }}
        loop={true}
        navigation
        pagination={{ type: "bullets" }}
      >
        {renderSlides()}
      </Swiper>
    </>
  )
}

export default HeroCarousel
