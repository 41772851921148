import React from "react"
import newsletterStyles from "./newsletter.module.scss"
import { useForm } from "react-hook-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons"
import addToMailchimp from "gatsby-plugin-mailchimp"

function Newsletter({ setIsLoading, setIsSuccessful, setIsError, setMessage }) {
  const { register, handleSubmit, errors } = useForm()
  const onSubmit = e => {
    setIsLoading(true)
    addToMailchimp(e.email)
      .then(({ msg, result }) => {
        // console.log("msg", `${result}: ${msg}`)
        setIsLoading(false)
        if (result !== "success") {
          setIsError(true)
          if (msg.includes("already subscribed")) {
            setMessage("You are already subscribed to our mailing list. Stay tuned for updates!")
            return
          }
        }
        setIsSuccessful(true)
        setMessage(msg)
      })
      .catch(err => {
        setIsLoading(false)
        setIsError(true)
        setMessage(err)
      })
  }

  return (
    <form className={newsletterStyles.form} onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="email" className={newsletterStyles.emailIcon} >
        <FontAwesomeIcon icon={faEnvelopeSquare} />
      </label>
      <input
        className={newsletterStyles.email}
        name="email"
        type="email"
        placeholder="EMAIL"
        ref={register({ required: true })}
      />
      <button className={newsletterStyles.submit} type="submit">
        Submit
      </button>
      {errors.email && (
        <span className={newsletterStyles.spanError}>
          You need to enter your email
        </span>
      )}
    </form>
  )
}

export default Newsletter
