import React from "react"
import landingParallaxStyles from "./landingParallax.module.scss"

import VarietyStylesParallax from "./varietyStylesParallax"
import { Spring } from "react-spring"
import VisibilitySensor from "react-visibility-sensor"

function LandingParallax(props) {
  return (
    <div className={landingParallaxStyles.container}>
    
      <VisibilitySensor partialVisibility={true}>
        {({ isVisible }) => (
          <Spring
            to={{
              transform: isVisible ? "translatex(0)" : "translatex(-10%)",
              opacity: isVisible ? 1 : 0,
            }}
          >
            {({ transform, opacity }) => (
              <div
                className={landingParallaxStyles.textContainer}
                style={{ transform, opacity }}
              >
                <h2 className={landingParallaxStyles.textHeader}>
                  We make clothes that women want
                </h2>
                <p className={landingParallaxStyles.textDescription}>
                  Our design and manufacturing process is curated to be the best
                  for you. We don't skimp out on what makes clothes desirable.
                  We utilize the best when it comes to designing and creating
                  apparel. That's just what we do.
                </p>
              </div>
            )}
          </Spring>
        )}
      </VisibilitySensor>

      <VarietyStylesParallax
        className={landingParallaxStyles.parallax}
        transShopBag={props.transShopBag}
        transLogo={props.transLogo}
        transDress={props.transDress}
        transBlueSkirt={props.transBlueSkirt}
        transWhiteTop={props.transWhiteTop}
        transBlackCoat={props.transBlackCoat}
        anime={props.anime}
      />

      <VisibilitySensor partialVisibility={true}>
        {({ isVisible }) => (
          <Spring
            to={{
              transform: isVisible ? "translatex(0)" : "translatex(10%)",
              opacity: isVisible ? 1 : 0,
            }}
          >
            {({ transform, opacity }) => (
              <div
                className={landingParallaxStyles.textContainer}
                style={{ transform, opacity }}
              >
                <h2 className={landingParallaxStyles.textHeader}>
                  Shop our variety of selections
                </h2>
                <p className={landingParallaxStyles.textDescription}>
                  We aim to be a one-stop shop for all your clothing needs.
                  Check out our selections suited to your lifestyle and beauty!
                </p>
              </div>
            )}
          </Spring>
        )}
      </VisibilitySensor>
    </div>
  )
}

export default LandingParallax
