import React from "react"
import landingPromosStyles from "./landingPromos.module.scss"

import { useSpring, animated } from "react-spring"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShippingFast } from "@fortawesome/free-solid-svg-icons"
import { faPercentage } from "@fortawesome/free-solid-svg-icons"
import { faSwatchbook } from "@fortawesome/free-solid-svg-icons"
import { Link as ScrollLink } from "react-scroll"

function LandingPromos() {
  const animation = useSpring({
    from: { transform: "scale(1)" },
    to: async next => {
      while (1) {
        await next({ transform: "scale(1.5)" })
        await next({ transform: "scale(1)" })
      }
    },
    config: { tension: 100 },
  })

  return (
    <div className={landingPromosStyles.promosContainer}>
      <div className={landingPromosStyles.promoContainer}>
        <animated.div
          style={animation}
          className={landingPromosStyles.iconContainer}
        >
          <FontAwesomeIcon icon={faSwatchbook} />
        </animated.div>
        <span className={landingPromosStyles.promo}>
          Quality garments made for you
        </span>
      </div>
      <div className={landingPromosStyles.promoContainer}>
        <animated.div
          style={animation}
          className={landingPromosStyles.iconContainer}
        >
          <FontAwesomeIcon icon={faShippingFast} />
        </animated.div>
        <span className={landingPromosStyles.promo}>
          Free Shipping for orders above ₱1,999
        </span>
      </div>
      <ScrollLink
        activeClass="active"
        to="landingNewsletter"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
      >
        <div className={landingPromosStyles.promoContainerNews}>
          <animated.div
            style={ animation }
            className={landingPromosStyles.iconContainer}
          >
            <FontAwesomeIcon icon={faPercentage} />
          </animated.div>
          <span className={landingPromosStyles.promo}>
            5% discount voucher for signing up to our newsletter
          </span>
        </div>
      </ScrollLink>
    </div>
  )
}

export default LandingPromos
