import React from "react"
import varietyStylesParallaxStyles from "./varietyStyles.module.scss"

import {  animated } from "react-spring"
import logo from "../../../static/image/White_Feather.png"

function VarietyStylesParallax(props) {

  return (
    <div
      className={varietyStylesParallaxStyles.container}
    >
      <animated.div
        className={varietyStylesParallaxStyles.shopBag}
        style={{ transform: props.anime.xy.to(props.transShopBag) }}
      />
      <animated.div
        className={varietyStylesParallaxStyles.logo}
        style={{
          transform: props.anime.xy.to(props.transLogo),
          backgroundImage: `url(${logo})`,
        }}
      />
      <animated.div
        className={varietyStylesParallaxStyles.dress}
        style={{ transform: props.anime.xy.to(props.transDress) }}
      />
      <animated.div
        className={varietyStylesParallaxStyles.blueSkirt}
        style={{ transform: props.anime.xy.to(props.transBlueSkirt) }}
      />
      <animated.div
        className={varietyStylesParallaxStyles.whiteTop}
        style={{ transform: props.anime.xy.to(props.transWhiteTop) }}
      />
      <animated.div
        className={varietyStylesParallaxStyles.blackCoat}
        style={{ transform: props.anime.xy.to(props.transBlackCoat) }}
      />
    </div>
  )
}

export default VarietyStylesParallax
