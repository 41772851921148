import React from "react"
import ContainerLoaderStyles from "./containerLoader.module.scss"

import BounceLoader from "react-spinners/BounceLoader"

function ContainerLoader() {
  return (
    <div className={ContainerLoaderStyles.container}>
      <BounceLoader
        className={ContainerLoaderStyles.spinner}
        size={200}
        color={"#70116b"}
        loading={true}
      />
    </div>
  )
}

export default ContainerLoader
