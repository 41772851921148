import React from "react"

import { useSpring } from "react-spring"
import HELM from "../components/helmet.js"

import Layout from "../components/layout/layout.js"
import HeroCarousel from "../components/heroCarousel/heroCarousel.js"
import Promos from "../components/landingPromos/landingPromos.js"
import LandingParallax from "../components/landingParallax/landingParallax.js"
import LandingGrid from "../components/landingGrid/landingGrid"
import FeaturedCatalog from "../components/featuredCatalog/featuredCatalog"

export default function Home() {
  // calculate depending on mouse position in entire page
  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
  const transShopBag = (x, y) => `translate3d(${x / 10}px,${y / 15 - 40}px,0)`
  const transLogo = (x, y) =>
    `translate3d(${x / 8.5 + 90}px,${y / 13 + 80}px,0)`
  const transDress = (x, y) =>
    `translate3d(${x / 7 + 160}px,${y / 10.5 - 100}px,0)`
  const transBlueSkirt = (x, y) =>
    `translate3d(${x / 8 - 30}px,${y / 11.5 + 160}px,0)`
  const transWhiteTop = (x, y) =>
    `translate3d(${x / 9 - 200}px,${y / 9.5 - 100}px,0)`
  const transBlackCoat = (x, y) =>
    `translate3d(${x / 9.5 + 200}px,${y / 12.5 + 170}px,0)`

  const [anime, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }))

  return (
    //eslint-disable-next-line
    <div
      style={{ outline: "none" }}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
      role="main"
    >
      <Layout>
        <HELM
          title="Home"
          keywords={[`Clothes`, `Teens`, `Plus Size`, `Maternity`]}
        />
        <HeroCarousel />
        <Promos />
        <LandingParallax
          transShopBag={transShopBag}
          transLogo={transLogo}
          transDress={transDress}
          transBlueSkirt={transBlueSkirt}
          transWhiteTop={transWhiteTop}
          transBlackCoat={transBlackCoat}
          anime={anime}
        />
        <LandingGrid />
        <FeaturedCatalog collection="Women" />
        <FeaturedCatalog collection="Plus" />
        <FeaturedCatalog collection="Maternity" />
      </Layout>
    </div>
  )
}
